import styled from "styled-components";
import { greyMedium, blueMedium } from "../../../utils/colorsLocal";
import {
  pagePadding,
  mobileBreakpoint,
  headerHeightMobile,
  headerHeightDesktop,
  zIndexHeader,
  stickyNavShadow
} from "../../../utils/styling";

export const Container = styled.div`
  position: absolute;
  z-index: ${zIndexHeader};
  height: ${headerHeightDesktop}px;
  background-color: white;
  ${stickyNavShadow};
  right: 0px;
  left: 0px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    height: ${headerHeightMobile}px;
  }
`;

export const Controls = styled.div`
  position: absolute;
  transition: left 200ms;
  left: ${({ showBack }) => (showBack ? 0 : -100)}px;
  height: 100%;
  width: 300px;
  top: 0px;

  @media (max-width: ${mobileBreakpoint}px) {
    left: ${({ showBack }) => (showBack ? 0 : -110)}px;
    width: 200px;
  }
`;

export const BackButton = styled.div`
  position: absolute;
  color: ${blueMedium};
  padding-right: 60px;
  padding-left: 15px;
  padding-top: 18px;
  cursor: pointer;
  height: 100%;
  width: 100px;
  left: 0px;
  top: 0px;

  &::before {
    content: "Back";
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    left: 54px;
    top: 16px;
  }

  @media (max-width: ${mobileBreakpoint}px) {
    padding-top: 13px;

    &::before {
      top: 11px;
    }
  }

  svg {
    fill: ${blueMedium};
  }
`;

export const AgentInfo = styled.div`
  position: absolute;
  left: ${pagePadding + 100}px;
  top: 14px;

  @media (max-width: ${mobileBreakpoint}px) {
    top: 8px;
  }
`;

export const MapButton = styled.div`
  position: absolute;
  right: ${pagePadding}px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  top: 20px;

  svg {
    fill: ${({ isMapOpen }) => (isMapOpen ? blueMedium : greyMedium)};
  }

  @media (max-width: ${mobileBreakpoint}px) {
    right: ${pagePadding}px;
    top: 15px;
  }
`;
