import React, { Component } from "react";
import PropTypes from "prop-types";
import { parseInt } from "lodash";
import { transformListings } from "../../../modules/listing";
import ListingsData from "../../listing/ListingsData";
import ListingHeader from "../listing/connected/ListingHeader";
import ListingSlider from "../listing/connected/ListingSlider";
import ListingDetails from "../listing/connected/ListingDetails";
import ListingControls from "../listing/connected/ListingControls";
import ListingDisclaimer from "../listing/connected/ListingDisclaimer";
import ListingHighlights from "../listing/connected/ListingHighlights";
import ListingDescription from "../listing/connected/ListingDescription";
import ListingFullscreenMedia from "../listing/connected/ListingFullscreenMedia";
import { SavedName } from "../search/styled/search-route";
import { Body, Grid, Disclaimer } from "../listing/styled/listing-route";
import { Content, Group, Sidebar } from "../listing/styled/listing-route";
import { SearchContainer, ListingContainer } from "./styled/shared-route";

class SharedRoute extends Component {
  componentDidMount() {
    this.props.getSharedSearch(this.props.slug);
  }

  componentDidUpdate(prevProps) {
    this.handleMlsCode(prevProps);
    this.handlePhotoProxy(prevProps);
    this.handleListingsFetched(prevProps);
    this.handlePaginationChange(prevProps);
  }

  render() {
    if (this.props.isFullscreenMediaOpen) {
      return <ListingFullscreenMedia />;
    }

    if (this.props.detailId) {
      return (
        <ListingContainer>
          <ListingHeader />
          <ListingSlider />
          <Body>
            <Grid>
              <Content>
                <Group>
                  <ListingHighlights />
                </Group>
                <Group>
                  <ListingDescription />
                </Group>
                <Group>
                  <ListingDetails />
                </Group>
              </Content>
              <Sidebar>
                <ListingControls />
                <Disclaimer>
                  <ListingDisclaimer />
                </Disclaimer>
              </Sidebar>
            </Grid>
          </Body>
        </ListingContainer>
      );
    } else {
      return (
        <SearchContainer>
          <SavedName>{this.props.sharedName}</SavedName>
          <ListingsData
            isPublic={true}
            isSharedRoute={true}
            isLoading={this.props.isLoading}
            isMapOpen={this.props.isMapOpen}
            isCards={this.props.isCards}
            page={this.props.page}
            limit={this.props.limit}
            sort={this.props.sort}
            listings={this.props.listings}
            parentPath={this.props.pathname}
            totalCount={this.props.totalCount}
            handleSelectSort={this.handleSelectSort}
            handleSelectLimit={this.handleSelectLimit}
            handleToggleView={this.handleToggleView}
            handleHoverListing={this.handleHoverListing}
            handleClickListing={this.handleClickListing}
            handleClickPage={
              this.props.isCollection ? null : this.handleClickPage
            }
          />
        </SearchContainer>
      );
    }
  }

  handleSelectSort = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_SORT", payload: value });
  };

  handleSelectLimit = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_LIMIT", payload: parseInt(value) });
  };

  handleClickPage = (value) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_LISTINGS_PAGE", payload: parseInt(value) });
  };

  handleToggleView = () => {
    const { isCards, emitAction } = this.props;
    emitAction({ type: "SET_IS_SHARED_CARDS", payload: !isCards });
  };

  handleHoverListing = (listingId) => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_HOVERED_LISTING_ID", payload: listingId });
  };

  handleClickListing = (listingId) => {
    const { emitAction } = this.props;
    const listing = this.props.listingsData[listingId] || {};
    emitAction({ type: "SET_SHARED_DETAIL_ID", payload: listing.id });

    this.props.trackEvent({
      isPublic: true,
      eventName: "shared_listing",
      metaData: {
        listing_id: listing.id,
        mls_number: listing.mlsnum,
        type: "shared_search"
      }
    });
  };

  handleMlsCode = ({ mlsCode }) => {
    if (!mlsCode && this.props.mlsCode) {
      this.props.getMlsConfigs(this.props.mlsCode);
    }
  };

  handlePhotoProxy = ({ photoProxyStrategy }) => {
    if (!photoProxyStrategy && this.props.photoProxyStrategy) {
      const payload = transformListings({
        photoProxyStrategy: this.props.photoProxyStrategy,
        listings: this.props.rawListings
      }).reduce(
        (state, listing) => {
          return {
            order: [...state.order, listing.id],
            data: { ...state.data, [listing.id]: listing }
          };
        },
        { order: [], data: {} }
      );

      this.props.emitAction({ type: "TRANSFORM_RAW_SHARED_LISTINGS", payload });
    }
  };

  handleListingsFetched = ({ hasListings, emitAction }) => {
    if (!hasListings && this.props.hasListings) {
      if (this.props.isSingleton) {
        const listing = (this.props.rawListings[0] || {}).attributes;
        emitAction({ type: "SET_SHARED_DETAIL_ID", payload: listing.id });

        this.props.trackEvent({
          isPublic: true,
          eventName: "shared_listing",
          metaData: {
            listing_id: listing.id,
            mls_number: listing.mlsnum,
            type: "direct_share"
          }
        });
      } else {
        this.props.trackEvent({
          isPublic: true,
          eventName: "shared_search",
          metaData: { criteria: this.props.filters }
        });
      }
    }
  };

  handlePaginationChange = ({ page, limit, sort }) => {
    const isNewPage = page !== this.props.page;
    const isNewLimit = limit !== this.props.limit;
    const isNewSort = sort !== this.props.sort;

    if (isNewPage || isNewLimit || isNewSort) {
      this.props.getSharedSearch(this.props.slug);
    }
  };
}

SharedRoute.propTypes = {
  slug: PropTypes.string,
  mlsCode: PropTypes.string,
  detailId: PropTypes.string,
  isLoading: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isCollection: PropTypes.bool,
  isCards: PropTypes.bool,
  sharedName: PropTypes.string,
  hasListings: PropTypes.bool,
  page: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
  listings: PropTypes.array,
  pathname: PropTypes.string,
  totalCount: PropTypes.number,
  listingsData: PropTypes.object,
  photoProxyStrategy: PropTypes.string,
  isFullscreenMediaOpen: PropTypes.bool,
  getSharedSearch: PropTypes.func,
  getMlsConfigs: PropTypes.func,
  trackEvent: PropTypes.func,
  emitAction: PropTypes.func
};

export default SharedRoute;
