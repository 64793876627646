import { connect } from "react-redux";
import { logoutUser } from "../../../actions/session";
import { getFilters } from "../../../modules/search";
import { emitAction } from "../../../utils/redux";
import { history, isAgentRoute, isCollectionRoute, isSearchRoute, isSearchableRoute } from "../../../utils/routing";
import Header from "../Header";

function select(store, { location: { pathname } }) {
  const filters = getFilters({ queryObject: store.queryObject, pathname });
  const fromCollection = isCollectionRoute(store.application.lastPathname);
  const fromSearch = isSearchRoute(store.application.lastPathname);
  const fromAgent = isAgentRoute(store.application.lastPathname);

  return {
    pathname,
    isNavOpen: store.application.isNavOpen,
    isOmniFocused: store.omniSearch.isFocused,
    isAdvancedOpen: store.advancedSearch.isOpen,
    isMapOpen: store.mapSearch.isOpen,
    isSwitcherEnabled: store.application.isSwitcherEnabled,
    hasFilters: filters.length > 0,
    showBackButton: fromAgent || fromSearch || fromCollection,
    isLoggedInAs: store.currentUser.isLoggedInAs,
    avatarUrl: store.currentUser.avatarUrl,
    initials: store.currentUser.initials,
    userName: store.currentUser.name,
    userId: store.currentUser.id,
    userEmail: store.currentUser.email,
    id: store.currentUser.id,
    jwt: store.currentUser.jwt,
    mlsCode: store.currentUser.mlsCode,
    goBack: () => history.goBack(),
    queryObject: store.queryObject,
    isSearchable: isSearchableRoute({
      sharedSearch: store.sharedSearch,
      pathname
    })
  };
}

const actions = {
  emitAction,
  logoutUser
};

export default connect(select, actions)(Header);
