import React, { Component } from "react";
import PropTypes from "prop-types";
import MapIcon from "../icons/MapIcon";
import { zIndexHeader } from "../../utils/styling";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import { AgentInfoDropdown } from "@wrstudios/components";
import { AgentInfo, MapButton } from "./styled/public-header";
import { Container, Controls, BackButton } from "./styled/public-header";

class PublicHeader extends Component {
  render() {
    if (this.props.isNotFound) {
      return <Container />;
    } else {
      return (
        <Container>
          <Controls showBack={this.props.showBack}>
            <BackButton onClick={this.handleClickBack}>
              <ChevronLeftIcon />
            </BackButton>
            <AgentInfo>
              <AgentInfoDropdown
                isAuthed={this.props.isAuthed}
                hasPhoneNumber={this.props.hasPhoneNumber}
                hasCompleteAddress={this.props.hasCompleteAddress}
                firstName={this.props.firstName}
                initials={this.props.initials}
                avatar={this.props.avatar}
                name={this.props.name}
                email={this.props.email}
                phone={this.props.phone}
                company={this.props.company}
                city={this.props.city}
                state={this.props.state}
                zip={this.props.zip}
                address={this.props.address}
                officePhone={this.props.officePhone}
                zIndex={zIndexHeader + 1}
              />
            </AgentInfo>
          </Controls>
          {this.props.showMap && (
            <MapButton
              isMapOpen={this.props.isMapOpen}
              onClick={this.handleClickMap}>
              <MapIcon />
            </MapButton>
          )}
        </Container>
      );
    }
  }

  handleClickMap = () => {
    const { isMapOpen, emitAction } = this.props;
    emitAction({ type: "SET_IS_MAP_OPEN_VIA_USER", payload: !isMapOpen });
  };

  handleClickBack = () => {
    const { emitAction } = this.props;
    emitAction({ type: "SET_SHARED_DETAIL_ID", payload: "" });
  };
}

PublicHeader.propTypes = {
  isAuthed: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  isNotFound: PropTypes.bool,
  showMap: PropTypes.bool,
  showBack: PropTypes.bool,
  hasPhoneNumber: PropTypes.bool,
  hasCompleteAddress: PropTypes.bool,
  firstName: PropTypes.string,
  initials: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  address: PropTypes.string,
  officePhone: PropTypes.string,
  emitAction: PropTypes.func
};

export default PublicHeader;
